import React from "react"

const weights = {
  s: "1-2 kg",
  m: "2-3 kg",
  l: "3-5 kg",
}

function Weight({ sizes }) {
  const num = sizes.length
  return (
    <div className={`grid gap-4 grid-cols-3`}>
      {sizes.map(weight => (
        <div key={weight} className="group productDescBox">
          <span className="font-ms font-bold text-lime-900 uppercase">
            {weight}
          </span>
          <span className="font-lf font-medium capitalize text-base">
            {weights[weight]}
          </span>

          <span
            className="pointer-events-none absolute -inset-px rounded-md"
            aria-hidden="true"
          ></span>
        </div>
      ))}
    </div>
  )
}

export default Weight
