import React from "react"

const colorAlternatives = {
  brown: "bg-amber-700",
  white: "bg-white",
  grey: "bg-gray-300",
  black: "bg-black",
  orange: "bg-orange-500",
}

const Shade = ({ color }) => {
  return (
    <label
      className="relative -m-0.5 flex items-center justify-center rounded-full p-0.5 ring-gray-400 focus:outline-none"
      title={color}
    >
      <input
        type="radio"
        name="color-choice"
        value="brown"
        className="sr-only"
        aria-labelledby="color-choice-5-label"
      />
      <span id="color-choice-0-label" className="sr-only">
        Brown
      </span>
      <span
        aria-hidden="true"
        className={`h-6 w-6 rounded-full border border-black border-opacity-25 bg-opacity-80 ${colorAlternatives[color]}`}
      ></span>
    </label>
  )
}

export default Shade
