import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Thumbs } from "swiper"
import { FadeIn } from "../components/animation"
import { MotionDiv, MotionSection } from "../components/animation/elements"
import { fadeInLeft, fadeInUp } from "../components/animation/fade"
import CTA from "../components/CTA"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import "swiper/css"
import "swiper/css/navigation"
import { useState } from "react"
import "../styles/product.css"
import Shade from "../components/shade"
import Weight from "../components/weight"

function Product({ data }) {
  const {
    title,
    date,
    category,
    colors,
    specification,
    size,
    description,
    images,
  } = data.markdownRemark.frontmatter

  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const headerProps = {
    title: category,
    caption: title,
  }

  return (
    <Layout>
      <SEO title="Product" />
      {/* <Header {...headerProps} /> */}
      <Container className="mt-16 md:mt-20">
        <div className="relative z-10">
          <FadeIn
            tag="div"
            className="flex min-h-full items-stretch justify-center text-center md:items-center "
          >
            <div className="flex w-full transform text-left text-base transition lg:max-w-5xl">
              <div className="relative flex w-full items-center overflow-hidden">
                <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                  {/* Slider */}
                  <MotionDiv
                    variant={fadeInLeft}
                    className="aspect-w-2 aspect-h-3 col-span-12 overflow-hidden md:col-span-5"
                  >
                    <Swiper
                      modules={[Thumbs, Navigation]}
                      thumbs={{ swiper: thumbsSwiper }}
                      navigation
                      className="imgSlider bg-white rounded-lg border border-gray-300"
                    >
                      {images.map((thumbnail, index) => (
                        <SwiperSlide key={`swp-${index}`}>
                          <GatsbyImage
                            alt={thumbnail.alt}
                            image={getImage(thumbnail.image)}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <Swiper
                      modules={[Thumbs]}
                      slidesPerView={4}
                      spaceBetween={10}
                      watchSlidesProgress={true}
                      onSwiper={setThumbsSwiper}
                      className="thumbsSlider mt-4"
                    >
                      {images.map((thumbnail, index) => (
                        <SwiperSlide
                          key={`thumb-${index}`}
                          className="swiper-slide rounded-lg border border-gray-300 w-1/4 !h-20 bg-white p-1"
                        >
                          <GatsbyImage
                            alt={thumbnail.alt}
                            image={getImage(thumbnail.image)}
                            className="object-cover object-center h-full w-full"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </MotionDiv>

                  {/* Content Box */}
                  <MotionDiv
                    variant={fadeInUp}
                    className="col-span-12 md:col-span-7 border md:p-8 md:bg-white md:border-gray-300 rounded-lg"
                  >
                    <h2 className="font-lf text-2xl font-bold text-gray-900 sm:pr-12">
                      {title}
                    </h2>

                    <MotionSection
                      variant={fadeInUp}
                      aria-labelledby="information-heading"
                      className="mt-6"
                    >
                      <h3 id="information-heading" className="sr-only">
                        Product information
                      </h3>
                      <h4 className="font-ms text-sm font-medium uppercase text-gray-900">
                        Description
                      </h4>

                      <div className="group relative flex flex-col items-start md:items-center justify-center rounded-md border border-lime-500 bg-white py-3 px-4 text-base font-base  text-gray-900 shadow-sm hover:bg-lime-100 focus:outline-none font-lf mt-4">
                        <p>{description}</p>
                      </div>
                    </MotionSection>

                    <section aria-labelledby="options-heading" className="mt-6">
                      <h3 id="options-heading" className="sr-only">
                        Product options
                      </h3>

                      <div>
                        <h4 className="font-ms text-sm font-medium uppercase text-gray-900">
                          Color
                        </h4>

                        <fieldset className="mt-4">
                          <legend className="sr-only">Choose a color</legend>
                          <div className="group relative flex md:inline-flex space-x-3 justify-start md:justify-center rounded-md border border-lime-500 bg-white py-3 px-4 shadow-sm hover:bg-lime-100 focus:outline-none">
                            {colors.map(color => (
                              <Shade key={color} color={color} />
                            ))}
                          </div>
                        </fieldset>
                      </div>

                      <MotionDiv variant={fadeInUp} className="mt-6">
                        <div className="flex items-center justify-between">
                          <h4 className="font-ms text-sm font-medium uppercase text-gray-900">
                            Material Specifications
                          </h4>
                        </div>

                        <fieldset className="mt-4">
                          <legend className="sr-only">
                            Material Specifications
                          </legend>
                          <div className="flex flex-col space-y-4">
                            <div className="group productDescBox">
                              <span className="font-ms font-bold text-lime-900 uppercase">
                                Material
                              </span>
                              <span className="font-lf font-medium capitalize text-base">
                                {specification.material}
                              </span>
                            </div>

                            <div className="group productDescBox">
                              <span className="font-ms font-bold uppercase text-lime-900">
                                GSM
                              </span>
                              <span className="font-lf font-medium capitalize text-base">
                                {specification.gsm}
                              </span>

                              <span
                                className="pointer-events-none absolute -inset-px rounded-md"
                                aria-hidden="true"
                              ></span>
                            </div>

                            {specification.handle && (
                              <div className="group productDescBox">
                                <span className="font-ms font-bold text-lime-900 uppercase">
                                  Handle
                                </span>
                                <span className="font-lf font-medium capitalize text-base">
                                  {specification.handle}
                                </span>

                                <span
                                  className="pointer-events-none absolute -inset-px rounded-md"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </div>
                        </fieldset>
                      </MotionDiv>

                      <MotionDiv variant={fadeInUp} className="mt-6 max-w-sm">
                        <div className="flex items-center justify-between">
                          <h4 className="font-ms text-sm font-medium uppercase text-gray-900">
                            Size
                          </h4>
                          {/* <a
                              href="#"
                              id="modalEl"
                              className="font-rw text-sm font-medium text-indigo-600 hover:text-indigo-500"
                              // onClick="modal.onShow(this)"
                            >
                              Size guide
                            </a> */}
                        </div>

                        <fieldset className="mt-4">
                          <legend className="sr-only">Size</legend>
                          <Weight sizes={size} />
                        </fieldset>
                      </MotionDiv>
                    </section>
                  </MotionDiv>
                </div>
              </div>
            </div>
          </FadeIn>
        </div>
      </Container>
      <CTA />
    </Layout>
  )
}

export default Product

export const pageQuery = graphql`
  query productDetails($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        category
        colors
        date
        images {
          alt
          image {
            childImageSharp {
              gatsbyImageData(height: 365)
            }
          }
        }
        description
        size
        specification {
          gsm
          handle
          material
        }
        templateKey
        title
      }
    }
  }
`
